.c-sidebar-nav-title {
  margin-top: 0px;
}

.c-sidebar {
  position: absolute;
}

.c-header {
  max-height: 50px;
}

@media (max-width: 455px) {
  .breadcrumb {
    display: none;
  }
}

.c-header-nav {
  position: absolute;
  right: 0;
}
