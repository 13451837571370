// Here you can add other styles

// Common
.sidebar-logo {
  width: 80%;
  padding-top: 10px;
  height: auto;
  align-self: center;
}

.invis-create-btn,
.invis-create-btn:focus,
.invis-create-btn:active:focus {
  outline: none;
  box-shadow: none;
  color: #2eb85c;
}

.invis-btn,
.invis-btn:focus,
.invis-btn:active:focus {
  padding: 5px;
  outline: none;
  box-shadow: none;
  border: none;
  background: none;
}

.invis-create-btn:hover {
  color: #208040;
}

// Loading spinner

.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.mobileMenuButton {
  color: white;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
}

.mobileMenuButton:hover {
  background-color: rgb(45, 55, 77);
}

@media (min-width: 992px) {
  .mobileMenuButton {
    display: none;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
